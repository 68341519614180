/**
 * A Helper Method that extracts the slug parameter from the url
 */
export const extractSlugFromUrl = (parts: string[]) => {
  if (!parts.length) return '';
  // check if it contains var
  if (parts.includes('var')) {
    return parts.at(0);
  }

  return parts.at(parts.length - 1);
};
