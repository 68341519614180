<template>
  <div v-if="pending" class="w-full container mx-auto py-12 h-full flex items-center justify-center">
    <LoadingProductsListPage class="w-full" />
  </div>
  <ContainersCategoryContent v-else-if="data && data.type === 'category'" :category="data?.pageData.payload" />

  <ContainersProductWrapper v-else-if="data && data.type === 'product'" :product="data?.pageData.payload" />
</template>

<script setup lang="ts">
import { ProductDocument, ProductQuery } from '~/graphql/product';
import { extractSlugFromUrl } from '~/utils/common';

definePageMeta({
  middleware: ['home'],
  /**
   * Prevents re-rendering if the path is a variant of a product
   */
  key: route => {
    const parts = route.params.slug as string[];

    // is variant path
    if (parts.includes('var') || parts.length === 1) {
      return parts[0];
    }

    if (parts.length > 1) {
      return parts[parts.length - 1];
    }

    return route.fullPath;
  },
});

const route = useRoute();

const { pending, data } = useAsyncData(async () => {
  const useFetchProductBySlug = fetchProductBySlugFactory({
    query: ProductDocument,
    mapProduct: product => {
      return {
        erpId: (product as ProductQuery['product'])?.erp_id,
      };
    },
  });

  const content = await useResolveSlugParameter(
    extractSlugFromUrl(route.params.slug as string[]) || '',
    useFetchProductBySlug
  );

  if (content?.type === 'product') {
    return {
      type: 'product',
      pageData: content,
    };
  }

  if (content?.type === 'category') {
    return {
      type: 'category',
      pageData: content,
    };
  }

  return null;
});
</script>
