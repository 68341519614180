<template>
  <div v-if="categories && categories.length" class="flex flex-col gap-y-1">
    <div class="sticky top-10">
      <h2 class="text-base font-bold mb-4">{{ category.name }}</h2>
      <ul class="text-sm">
        <li v-for="item in categories" :key="item?.id || 0">
          <button
            :class="{
              'font-bold': category.id === item.id,
            }"
            @click="onSelectCategory(item)"
          >
            {{ item.name }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Category } from '@robustastudio/e-commerce/dist/runtime';

const props = defineProps({
  category: {
    type: Object as PropType<Category>,
    default: () => ({}),
  },
});

const { categories: pageCategories, category: selectedCategory } = useCategorySlider();
const categories = ref<(typeof pageCategories)['value']>([]);

/**
 * on mounted set the selected category component to the one injected
 */
onMounted(() => {
  /**
   * set the selected category
   */
  selectedCategory.value = props.category;
});

const emit = defineEmits(['category']);
function onSelectCategory(category: Category) {
  if (!(category.url || category.url_key)) {
    return;
  }
  /**
   * set the selected category
   */

  emit('category', `${category.url || category.url_key}`);
}

const unwatchCategories = watch(pageCategories, value => {
  categories.value = [...value];
  categories.value[0].name = 'All Products';
  unwatchCategories();
});
</script>
