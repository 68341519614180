<template>
  <div class="mt-5 pb-10">
    <section class="CategoryPage__banner px-5 lg:px-0">
      <h1
        class="md:hidden [ mt-6 md:mt-11 mb-2 md:mb-8 ] [ text-center text-xl md:text-3xl font-bold text-primary-1-100 ]"
      >
        {{ category.name }}
      </h1>
    </section>

    <OfferSlider
      v-if="options.supportOffers && offers && offers.length"
      v-slot="{ item }"
      class="container mx-auto md:h-auto relative mt-4 mb-11 md:mb-17"
      :items="offers"
      :auto-play="3000"
      :desktop-slides="1"
    >
      <OfferCard
        :id="item.id"
        :image="item.image"
        :href="item.href || '/'"
        :title="item.title || ''"
        :description="item.description || ''"
        width="1200"
        height="515"
      />
    </OfferSlider>

    <ProductsListing
      v-model="facets"
      :category-id="category.id?.toString()"
      :render-excluded-facets="['category_uid', 'category_id', 'has_deal']"
    >
      <template #filters>
        <CategoryList class="CategoryList hidden md:block" :category="category" @category="onSelectCategory" />
      </template>
    </ProductsListing>
  </div>
</template>

<script setup lang="ts">
import { Category } from '@robustastudio/e-commerce/dist/runtime/composables/categories';
import { MappedAggregation } from '@robustastudio/e-commerce/dist/runtime/composables/facets';

const { t: $t } = useI18n({
  useScope: 'local',
});
const props = defineProps({
  category: {
    type: Object as PropType<Category>,
    default: null,
  },
});
const options = {
  supportOffers: true,
};

provide(CATEGORY_CONTEXT, ref(props.category));

// const { push: redirect } = useRouter();
const { offers } = useCategoryOffers(props.category.id || 0);

const { redirect } = useAppRouter();

const facets = ref<MappedAggregation[]>([]);

function onSelectCategory(url: string) {
  /**
   * set the selected category
   */
  redirect(`/${url}`);
}

useAppSeoMeta(
  computed(() => ({
    title: props.category.meta_title || props.category.name,
    description: props.category.meta_description,
    keywords: props.category.meta_keywords,
    image: props.category.image,
  }))
);
</script>

<i18n>
{
  "en": {
    "notFoundTitle": "We couldn't find products that match your criteria",
    "notFoundTip": "Try using different filters to find other products",
    "products": "Products",
    "subCategories": "Subcategories",
    "search": "Search Results",
    "subtitle": "Exquisite Essences"
  },
  "ar": {
    "notFoundTitle": "لم يتم العثور على منتجات متطابقة",
    "notFoundTip": "من فضلك قم باختيار مرشحات مختلفة للعثور على منتجات اخري",
    "products": "منتجات",
    "subCategories": "اقسام فرعيه",
    "search": "نتيجه البحث",
    "subtitle": "جوهر رائع"
  }
}
</i18n>

<style lang="postcss" scoped>
.CategoryList {
  width: 200px;
}

@media (min-width: 768px) and (max-width: 1600px) {
  .OfferSlider {
    margin-left: 230px;
  }
}
</style>
